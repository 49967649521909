import infoSvg from '../../public/information-circle.svg';

interface CurrentEpochProps {
  epoch: number;
}

function CurrentEpoch({ epoch }: CurrentEpochProps) {
  return (
    <div className="bg-gray-3 border border-gray-2 rounded">
      <div className="flex justify-between mx-2 mt-2">
        <p className="text-gray-1 font-medium">Current Epoch</p>
        <img
          src={infoSvg}
          alt="info"
          data-tooltip-id="my-tooltip"
          data-tooltip-html="An epoch lasts for 14 days."
        />
      </div>
      <div className="flex justify-center mt-4 mb-6">
        <p className="text-white text-6xl font-medium">{epoch}</p>
      </div>
    </div>
  );
}

export default CurrentEpoch;
