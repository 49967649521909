import { useState, useEffect } from 'react';
import axios from 'axios';

export function useQueryMarketData(network: string) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://gateway.blast-prod.vertexprotocol.com/v1/symbols',
        );
        // Remove mBONK from data
        const filteredData = response.data.filter(
          (item: any) => item.product_id !== 48,
        );
        setData(filteredData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading };
}
